import ScrollReveal from "scrollreveal";

/*==========================================
  Scroll Reveal 
===========================================*/

const scrollRevealAfterCallback = el => {
    el.classList.add("v-sr-is-visible");
};

const scrollRevealBeforeCallback = el => {
    el.classList.add("v-sr-before-visible");
};

export const srOptions = {
    distance: "24px",
    viewOffset: {
        top: 24
    },
    duration: 1000,
    beforeReveal: scrollRevealBeforeCallback,
    afterReveal: scrollRevealAfterCallback
};

export const initScrollReveal = () => {
    const scrollRevealSelectors = "";

    ScrollReveal().reveal(scrollRevealSelectors, srOptions);

    if (document.querySelector(".v-scroll-reveal")) {
        ScrollReveal().reveal(".v-scroll-reveal", srOptions);
    }
};
